// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from "src/app/services/logger/LogLevel";

export interface Environment {
  ProjectName: string;
  appVersion: string;
  version: string;
  production: boolean;
  mockData: boolean;
  defaultLanguage: string;
  defaultCountry: string;
  languages: string[];
  users: { username: string, password: string }[];
  endpoint: string;
  trackingId: string;
  logLevel: LogLevel;
}

export const environment: Environment = {
  ProjectName: 'Niko project wizard',
  appVersion: 'Dev: ' + require('../../package.json').version,
  version: require('../../package.json').version,
  production: false,
  mockData: false,
  defaultLanguage: 'en_GB',
  defaultCountry: 'BE',
  languages: [
      'nl_BE',
      'en_GB'
  ],
  users: [
    {
      username: 'tim@installateur.be',
      password: 'niko'
    }
  ],
  //endpoint: 'http://dev.gobliss.net/api',
  //endpoint: 'https://dsa.dev.niko.tcl.rocks/api',
  endpoint: 'https://api.dev.projectwizard.niko.eu/api',
  trackingId: 'G-XNV4KNGQLS',
  logLevel: LogLevel.ALL//,
  // authURL: 'http://dev.gobliss.net/api/authenticate',
  // authURL: 'http://10.20.110.137:8080/api/authenticate'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
